import * as React from 'react';
import * as S from './Rating.styled';
import { FC, memo, useEffect, useState } from 'react';
import { fetchPlayers, selectPlayers, selectRatingLoading } from '@src/redux/slices/ratingSlice';
import { getCoinsForNextLevel, getHumanBalance } from '@common/number';
import { rankDescriptions, ranks } from '@common/string';
import { selectHumanTotalCoins, selectLevel, selectRatingProgress } from '@src/redux/slices/playerSlice';
import { useAppDispatch, useHapticFeedback } from '@common/hooks';
import { useSelector } from 'react-redux';
import Main from '@layouts/Main';
import RatingGridLoader from '@components/Rating/RatingGridLoader';
import SVG from '@ui/SVG/SVG';

const Rating: FC = () => {
  const dispatch = useAppDispatch();
  const [impactOccurred] = useHapticFeedback();
  const level = useSelector(selectLevel);
  const humanTotalCoins = useSelector(selectHumanTotalCoins);
  const users = useSelector(selectPlayers);
  const isLoading = useSelector(selectRatingLoading);
  const [chosenLevel, setChosenLevel] = useState<number>(level);
  const progress = useSelector(state => selectRatingProgress(state, chosenLevel));

  useEffect(() => {
    const fetchingDispatch = dispatch(fetchPlayers(chosenLevel));
    return () => fetchingDispatch?.abort();
  }, [dispatch, chosenLevel]);

  return (
    <Main>
      <>
        <S.Header>
          <S.HeaderButton
            disabled={chosenLevel <= 1}
            onClick={() => {
              if (chosenLevel > 1) {
                impactOccurred('soft');
                setChosenLevel(currentLevel => currentLevel - 1);
              }
            }}
          >
            <SVG name="left" />
          </S.HeaderButton>
          <S.HeaderContent>
            <S.LevelName>{ranks[chosenLevel]}</S.LevelName>
            <S.CoinProgress>
              {level === chosenLevel ? (
                <>
                  {humanTotalCoins} / {getHumanBalance(getCoinsForNextLevel(level))}
                </>
              ) : `От ${getHumanBalance(getCoinsForNextLevel(chosenLevel - 1))}`}
            </S.CoinProgress>
          </S.HeaderContent>
          <S.HeaderButton
            disabled={chosenLevel >= ranks.length - 1}
            onClick={() => {
              if (chosenLevel < ranks.length - 1) {
                impactOccurred('soft');
                setChosenLevel(currentLevel => currentLevel + 1);
              }
            }}
          >
            <SVG name="right" />
          </S.HeaderButton>
        </S.Header>
        <S.Progress disabled={chosenLevel >= ranks.length - 1}>
          <S.CurrentProgress $width={progress} />
        </S.Progress>
        <S.Description>
          {rankDescriptions[chosenLevel]}
        </S.Description>
        {isLoading ? <RatingGridLoader /> : (
          <div>
            {users.length ? (
              <S.Content>
                <S.Title>Рейтинг основан на доходе в час</S.Title>
                {users.map((user, index) => (
                  <S.Item key={`${index}-${user.total_coins}`}>
                    <S.ItemRating>{index + 1}</S.ItemRating>
                    <S.ItemName>{user.first_name || 'Неизвестный'}</S.ItemName>
                    <S.ItemBalance>
                      <SVG
                        name="coin"
                        width={14}
                        height={14}
                      />
                      <span>{getHumanBalance(user.earn_passive)}</span>
                    </S.ItemBalance>
                  </S.Item>
                ))}
              </S.Content>
            ) : (
              <S.Content>
                {level < chosenLevel ? (
                  <S.Message>Ещё нет участников в выбранной лиге. У вас все шансы стать первым!</S.Message>
                ) : (
                  <S.Message>Нет участников в выбранной лиге.</S.Message>
                )}
              </S.Content>
            )}
          </div>
        )}
      </>
    </Main>
  );
};

export default memo(Rating);
