import { TPlayer } from '@src/app/types';

/**
 * Заменяет все вхождения указанной подстроки на новую подстроку.
 * @param {string} inputString - Исходная строка.
 * @param {string} substring - Подстрока, которую нужно заменить.
 * @param {string} newSubstring - Подстрока, на которую нужно заменить.
 * @returns {string} Строка с заменёнными подстроками.
 */
export const replaceSubstrings = (inputString: string, substring: string, newSubstring: string): string => (
  inputString?.split(substring).join(newSubstring)
);

/**
 * Форматирует строку с описателями типа {0}.
 * @param {string} inputString - Исходная строка.
 * @param {...string} values - Значения для подстановки.
 * @returns {string} Отформатированная строка.
 */
export function formatString(inputString: string, ...values: Array<string>): string {
  if (!inputString || !values.length) return inputString;
  let result = inputString;
  values.forEach((value, index) => {
    result = replaceSubstrings(result, `{${index}}`, value);
  });

  return result;
}

/**
 * Возвращает склонение числительного для числа.
 * @param {number} value - Число.
 * @param {Array<string>} words - Массив склонений.
 * @returns {string} Склонение.
 */
export function pluralize(value: number, words: Array<string>): string {
  const cases = [2, 0, 1, 1, 1, 2];
  const index = (value % 100 > 4 && value % 100 < 20) ? 2 : cases[Math.min(value % 10, 5)];

  return words[index];
}

export const ranks = [
  'Без ранга',
  'Любопытствующий',
  'Наблюдатель',
  'Экспериментатор',
  'Знакомый с основами',
  'Энтузиаст',
  'Следователь трендов',
  'Создатель образов',
  'Коллекционер',
  'Конструктор стиля',
  'Трендсеттер',
  'Модный гуру',
  'Шикарный модник',
  'Светский лев',
  'Икона стиля',
  'Модный магнат',
  'Модный мессия',
];

export const rankDescriptions = [
  '',
  'Вы только начинаете интересоваться модой. Ваши знания ограничены, но любопытство и желание развиваться ведут вас вперёд.',
  'Вы внимательно изучаете тренды и стили, пытаясь понять, что подходит именно вам. Ваши первые попытки подражания известным иконам моды дают результаты.',
  'Вы активно пробуете разные образы, иногда удачно, иногда нет. Но каждый неудачный выбор приближает вас к идеальному образу.',
  'Вы освоили базовые принципы сочетания цветов и стилей. Ваши друзья начинают замечать изменения в вашем внешнем виде.',
  'Вы уже уверенно выбираете одежду, которая подчёркивает вашу индивидуальность. Мода становится важной частью вашей жизни.',
  'Вы научились чувствовать тренды и понимать, какие вещи будут актуальны в ближайшее время. Ваши покупки становятся всё более продуманными.',
  'Вы мастерски комбинируете различные элементы гардероба, создавая уникальные и запоминающиеся образы. Окружающие начинают обращать на вас внимание.',
  'В вашем шкафу появляются редкие и эксклюзивные вещи. Вы знаете толк в качественных материалах и уникальных дизайнах.',
  'Вы способны создать идеальный образ практически из любых вещей. Ваш стиль становится узнаваемым и уникальным.',
  'Вы уже уверенно создаёте актуальные образы и задаёте тон своим друзьям и знакомым. Они начинают обращаться к вам за советами.',
  'Ваш стиль привлекает внимание даже на улицах города. Люди начинают спрашивать, где вы покупаете такие вещи и как сочетаете их между собой.',
  'Вы мастерски комбинируете люксовые бренды с масс-маркетом, создавая уникальные и изысканные образы. В вашем гардеробе уже есть настоящие жемчужины.',
  'Вы стали частым гостем на светских мероприятиях, и ваш стиль обсуждают в модных журналах. Вас приглашают на закрытые показы и вечеринки.',
  'Ваш стиль стал культовым. Каждый ваш выход в свет — событие, которое обсуждается в социальных сетях и на страницах глянцевых журналов. Вы — живое воплощение элегантности и вкуса.',
  'Вы не просто икона стиля, вы — законодатель моды. Ваши образы копируют миллионы, а каждое ваше появление вызывает бурю обсуждений. Вы управляете модным миром, устанавливая новые стандарты красоты и стиля.',
  'Вы достигли вершин модного мира. Ваше имя — символ безупречного вкуса и стиля. Вы вдохновляете целые поколения и оставляете неизгладимый след в истории моды.',
];

export const getPlayerName = (player: TPlayer): string => {
  if (player.first_name && player.last_name) {
    return `${player.last_name} ${player.first_name}`;
  }
  if (player.first_name) {
    return player.first_name;
  }
  if (player.username) {
    return player.username;
  }
  return `Игрок #${player.telegram_id}`;
};
