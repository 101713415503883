import styled, { css } from 'styled-components';

export const TapContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto 0 0;
`;

type TTapButton = {
  $isTaping: boolean;
}

export const TapButton = styled.button<TTapButton>`${({ $isTaping }) => css`
    display: flex;
    transition: transform .2s ease-in-out, box-shadow .2s ease-in-out;
    color: #fff;
    transform: ${$isTaping ? 'scale(.95)' : 'scale(1)'};
    width: 87px;
    height: 367px;
`}`;

export const TapFooter = styled.div`
    margin: 24px 0 16px;
    display: flex;
    justify-content: center;
    width: 100%;
`;

export const Energy = styled.div`
    display: flex;
    align-items: center;
    margin-right: 27px;
`;

export const EnergyText = styled.div`
    margin-left: 10px;
    font-size: 15px;
    font-weight: 600;
`;

export const Animate = styled.div`
    pointer-events: none;
    user-select: none;

    div {
        position: fixed;
        z-index: 10;
        font-size: 32px;
        font-weight: 800;
        animation: appear 1s;

        @keyframes appear {
            0% {
                opacity: 1;
                transform: translate(0, 0);
            }

            100% {
                opacity: 0;
                transform: translate(0, -8rem);
            }
        }
    }
`;
