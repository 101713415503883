import * as React from 'react';
import * as S from './Capital.styled';
import { FC, memo } from 'react';
import { getHumanBalance } from '@common/number';
import { selectEarnPassive } from '@src/redux/slices/playerSlice';
import { useHapticFeedback } from '@common/hooks';
import { useSelector } from 'react-redux';
import LevelBar from '@components/LevelBar';
import PlayerBalance from '@components/PlayerBalance';
import SVG from '@ui/SVG/SVG';

type TProps = {
  title: string;
  children: React.JSX.Element | React.JSX.Element[];
}

const Capital: FC<TProps> = ({ title, children }) => {
  const [impactOccurred] = useHapticFeedback();
  const earnPassive = useSelector(selectEarnPassive);

  return (
    <S.Wrapper>
      <S.Header>
        <S.HeaderGrid>
          <LevelBar />
          <PlayerBalance />
          <S.InfoButton to="/info">
            <SVG name="question" />
          </S.InfoButton>
        </S.HeaderGrid>
        <S.Income>
          <S.IncomeLabel>
            Пассивный доход:
          </S.IncomeLabel>
          <SVG
            name="coin"
            width={14}
            height={14}
          />
          <S.IncomeValue>
            {getHumanBalance(earnPassive)}
          </S.IncomeValue>
        </S.Income>
      </S.Header>
      <S.Content>
        <S.Navigation>
          <S.Link
            onClick={() => impactOccurred('soft')}
            to="/"
          >
            <SVG
              name="mining"
              customColor="currentColor"
            />
          </S.Link>
          <S.Link
            onClick={() => impactOccurred('soft')}
            to="/boost"
          >
            <SVG
              name="boost"
              customColor="currentColor"
            />
          </S.Link>
          <S.Link
            onClick={() => impactOccurred('soft')}
            to="/investment"
          >
            <SVG
              name="passive"
              customColor="currentColor"
            />
          </S.Link>
        </S.Navigation>
        <S.Title>
          {title}
        </S.Title>
        {children}
      </S.Content>
    </S.Wrapper>
  );
};

export default memo(Capital);
