import { TItem } from '@src/app/types';
import { createAppSlice } from '@common/hooks';
import { sendRequest } from '@src/network';

type TRatingSliceState = {
  items: Array<TItem>;
  loading: boolean;
}

export const itemSlice = createAppSlice({
  name: 'item',
  initialState: {
    items: [],
    loading: true,
  } as TRatingSliceState,
  reducers: create => ({
    fetchItems: create.asyncThunk(
      async (_, { signal }) => sendRequest<Array<TItem>>('/item', {
        signal,
      }),
      {
        pending: state => {
          state.loading = true;
          state.items = [];
        },
        rejected: (state, action) => {
          console.log(action.error);
        },
        fulfilled: (state, action) => {
          state.loading = false;
          state.items = action.payload || [];
        },
      },
    ),
  }),
  selectors: {
    selectItems: state => state.items,
    selectItemsLoading: state => state.loading,
  },
});

export const {
  fetchItems,
} = itemSlice.actions;

export const {
  selectItems,
  selectItemsLoading,
} = itemSlice.selectors;

export default itemSlice.reducer;
