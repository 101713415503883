import * as React from 'react';
import * as S from './Friends.styled';
import { FC, memo, useCallback } from 'react';
import { getHumanBalance } from '@common/number';
import { ranks } from '@common/string';
import { selectReferralLink } from '@src/redux/slices/playerSlice';
import { selectReferrals } from '@src/redux/slices/referralSlice';
import { useHapticFeedback } from '@common/hooks';
import { useSelector } from 'react-redux';
import FriendGift from '@src/images/friend-gift.png';
import FriendPremiumGift from '@src/images/friend-premium-gift.png';
import Main from '@layouts/Main';
import SVG from '@ui/SVG/SVG';

const Friends: FC = () => {
  const [impactOccurred, notificationOccurred] = useHapticFeedback();
  const playerReferralLink = useSelector(selectReferralLink);
  const referrals = useSelector(selectReferrals);

  const shareLink = useCallback(() => {
    impactOccurred('soft');
    const shareText = encodeURIComponent('Играй со мной в AVATARIA!'
      + '\n💸 5k монет в качестве первого подарка'
      + '\n🔥 25k монет, если у тебя есть Telegram Premium');
    const shareUrl = encodeURIComponent(`https://t.me/AvatariaNftBot/start?startapp=${playerReferralLink}`);
    const link = `https://t.me/share/url?url=${shareUrl}&text=${shareText}`;
    Telegram.WebApp.openTelegramLink(link);
  }, [impactOccurred, playerReferralLink]);
  const copyLink = useCallback(() => {
    impactOccurred('soft');
    const link = `https://t.me/AvatariaNftBot/start?startapp=${playerReferralLink}`;

    navigator.clipboard.writeText(link).then(() => {
      notificationOccurred('success');
    }, err => {
      notificationOccurred('error');
      Telegram.WebApp.showAlert(`Ошибка при копировании ссылки: ${err}`);
    });
  }, [impactOccurred, notificationOccurred, playerReferralLink]);

  return (
    <Main>
      <>
        <S.HeaderTitle>Пригласите друзей!</S.HeaderTitle>
        <S.HeaderText>Вы и ваш друг получите бонусы</S.HeaderText>
        <S.HeaderItem>
          <img
            width={64}
            src={FriendGift}
            alt=""
          />
          <S.HeaderItemBody>
            <S.HeaderItemTitle>Пригласить друга</S.HeaderItemTitle>
            <S.HeaderItemText>
              <S.HeaderItemReward>
                +
                <SVG
                  name="coin"
                  width={14}
                  height={14}
                />
                5 000
              </S.HeaderItemReward>
              <span>для вас и вашего друга</span>
            </S.HeaderItemText>
          </S.HeaderItemBody>
        </S.HeaderItem>
        <S.HeaderItem>
          <img
            width={64}
            src={FriendPremiumGift}
            alt=""
          />
          <S.HeaderItemBody>
            <S.HeaderItemTitle>Пригласить друга с Telegram Premium:</S.HeaderItemTitle>
            <S.HeaderItemText>
              <S.HeaderItemReward>
                +
                <SVG
                  name="coin"
                  width={14}
                  height={14}
                />
                25 000
              </S.HeaderItemReward>
              <span>для вас и вашего друга</span>
            </S.HeaderItemText>
          </S.HeaderItemBody>
        </S.HeaderItem>
        <S.ButtonWrapper>
          <S.ShareButton onClick={shareLink}>
            + Пригласить друга
          </S.ShareButton>
          <S.CopyButton onClick={copyLink}>
            <SVG name="copy" />
          </S.CopyButton>
        </S.ButtonWrapper>
        <S.ReferralTitle>Список ваших друзей ({referrals.length})</S.ReferralTitle>
        <S.ReferralContent>
          {referrals.map((referral, index) => (
            <S.ReferralItem key={index}>
              <span>{referral.first_name || 'Неизвестный'} ({ranks[referral.level]})</span>
              <S.ReferralItemIncome>
                <SVG
                  name="coin"
                  width={14}
                  height={14}
                />
                {getHumanBalance(referral.earn_passive)}
              </S.ReferralItemIncome>
            </S.ReferralItem>
          ))}
        </S.ReferralContent>
      </>
    </Main>
  );
};

export default memo(Friends);
