import * as S from './Info.styled';
import { selectReferralLink } from '@src/redux/slices/playerSlice';
import { useHapticFeedback } from '@common/hooks';
import { useSelector } from 'react-redux';
import Main from '@layouts/Main';
import React, { FC, memo, useCallback } from 'react';
import SVG from '@ui/SVG/SVG';

const Info: FC = () => {
  const [impactOccurred, notificationOccurred] = useHapticFeedback();
  const referralLink = useSelector(selectReferralLink);

  const copyLink = useCallback(() => {
    impactOccurred('soft');

    navigator.clipboard.writeText(referralLink).then(() => {
      notificationOccurred('success');
    }, err => {
      notificationOccurred('error');
      Telegram.WebApp.showAlert(`Ошибка при копировании ID: ${err}`);
    });
  }, [impactOccurred, notificationOccurred, referralLink]);

  return (
    <Main>
      <>
        <S.Title>AVATARIA</S.Title>
        <S.Name>
          <span>ID {referralLink}</span>
          <S.NameButton onClick={() => copyLink()}>
            <SVG
              name="copy"
              width={16}
              height={16}
            />
          </S.NameButton>
        </S.Name>
        <S.Link
          href="mailto:game@avataria-nft.ru?subject=Вопрос по игре AVATARIA"
          target="_blank"
          rel="noreferrer"
        >Связь с поддержкой
        </S.Link>
        <S.Privacy>© 2024, AVATARIA</S.Privacy>
      </>
    </Main>
  );
};

export default memo(Info);
