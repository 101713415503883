import * as React from 'react';
import * as S from './UnavailablePlatform.styled';
import { FC, memo } from 'react';
import Main from '@layouts/Main';

const UnavailablePlatform: FC = () => (
  <Main>
    <S.Title>Ой-ёй!</S.Title>
    <S.Message>
      Приложение доступно только на&nbsp;мобильных устройствах.
    </S.Message>
    <S.Link
      href="mailto:game@avataria-nft.ru?subject=Вопрос по игре AVATARIA"
      target="_blank"
      rel="noreferrer"
    >Связь с поддержкой
    </S.Link>
    <S.Privacy>© 2024, AVATARIA</S.Privacy>
  </Main>
);

export default memo(UnavailablePlatform);
